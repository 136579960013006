import React, { Suspense } from "react";
import FullpageLoader from "./components/FullpageLoader";
import Providers from "./modules/Providers";
import AppRouter from "./modules/Router";

export default function App() {
  return (
    <Suspense fallback={<FullpageLoader />}>
      <Providers>
        <AppRouter />
      </Providers>
    </Suspense>
  );
}
