import answers from "./answers";
import campaigns from "./campaigns";
import chats from "./chats";
import emails from "./emails";
import events from "./events";
import guests from "./guests";
import notifications from "./notifications";
import projects from "./projects";
import questions from "./questions";

const api = {
  answers,
  campaigns,
  chats,
  emails,
  events,
  guests,
  notifications,
  projects,
  questions,
};

export default api;
