import { storeStatus } from "../utils";
import { createProject, deleteProject, fetchProjects } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const projectsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case fetchProjects.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
        error: null,
      };
    case fetchProjects.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.projects.map((project) => project.id),
        byId: payload.projects.reduce(
          (acc, project) => ({ ...acc, [project.id]: project }),
          {},
        ),
      };
    case fetchProjects.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case createProject.success.TYPE:
      return {
        ...state,
        allIds: [payload.project.id, ...state.allIds],
        byId: {
          ...state.byId,
          [payload.project.id]: payload.project,
        },
      };
    case deleteProject.success.TYPE:
      const allIdsWithoutDelete = state.allIds.filter(
        (id) => id !== payload.projectId,
      );

      return {
        ...state,
        allIds: allIdsWithoutDelete,
        byId: {
          ...state.byId,
          [payload.projectId]: undefined,
        },
      };

    default:
      return state;
  }
};

export default projectsReducer;
