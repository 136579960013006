import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, firestore } from "modules/firebase";

const subscribe = (handler) => {
  const user = auth.currentUser;

  const notificationsCollection = collection(firestore, "notifications");

  const queryRef = query(
    notificationsCollection,
    where("user", "==", user.uid),
    orderBy("timestamp", "desc"),
  );

  return onSnapshot(queryRef, (querySnapshot) => {
    const notifications = querySnapshot.docs.map((notification) => ({
      id: notification.id,
      ...notification.data(),
    }));
    handler(notifications);
  });
};

const toggleRead = async ({ notificationId, read }) => {
  const notificationRef = doc(firestore, "notifications", notificationId);

  await updateDoc(notificationRef, { read, updated_at: serverTimestamp() });
};

const deleteNotification = async ({ notificationId }) => {
  const notificationRef = doc(firestore, "notifications", notificationId);

  await deleteDoc(notificationRef);

  return "success";
};

const api = {
  subscribe,
  toggleRead,
  delete: deleteNotification,
};

export default api;
