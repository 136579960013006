import React, { useContext } from "react";
import { AuthContext } from "../../Auth";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  return (
    !isLoading && (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  );
};

export default PrivateRoute;
