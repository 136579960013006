import { makeActionCreator } from "redux-toolbelt";

const makeModalsSendMessageAction = makeActionCreator.withDefaults({
  prefix: "ui/modals/send_message/",
});

export const openModal = makeModalsSendMessageAction("OPEN_MODAL");
export const closeModal = makeModalsSendMessageAction("CLOSE_MODAL");

export const setFields = makeModalsSendMessageAction("SET_FIELDS");
