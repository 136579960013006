import { storeStatus } from "../utils";
import { fetchEmails } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const emailsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case fetchEmails.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
        error: null,
      };
    case fetchEmails.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.emails.map((email) => email.id),
        byId: payload.emails.reduce(
          (acc, email) => ({ ...acc, [email.id]: email }),
          {},
        ),
      };
    case fetchEmails.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };

    default:
      return state;
  }
};

export default emailsReducer;
