import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { firestore, functionsEndpoints } from "modules/firebase";
import { nanoid } from "nanoid";
import { listEvents, removeGuestsToEvent } from "./events";
import { csvReader } from "./utils";

export const importGuests = async ({ projectId, guestsCsv }) => {
  const guestsList = await csvReader(guestsCsv);

  const newGuests = [];

  for (const guest of guestsList) {
    try {
      const guestDoc = await createGuest({
        projectId,
        guest,
      });

      newGuests.push(guestDoc);
    } catch (error) {
      console.error(error);
    }
  }

  return newGuests;
};

export const createGuest = async ({ projectId, guest, parentGuest }) => {
  const { data: newGuest } = await functionsEndpoints.addGuest({
    projectId,
    guest,
    invitedBy: parentGuest?.id,
  });

  if (parentGuest) {
    const parentGroup = parentGuest.group ?? `${nanoid()}|${parentGuest.id}`;

    await addToGroup({ projectId, guestId: newGuest.id, group: parentGroup });

    newGuest.group = parentGroup;
  }

  return newGuest;
};

export const updateGuest = async ({ projectId, guestId, fields }) => {
  const guestRef = doc(firestore, "projects", projectId, "guests", guestId);

  await updateDoc(guestRef, {
    ...fields,
    updated_at: serverTimestamp(),
  });

  return fields;
};

export const listGuests = async ({ projectId }) => {
  const guestsRef = collection(firestore, "projects", projectId, "guests");

  const queryRef = query(guestsRef, orderBy("firstname", "asc"));

  const querySnapshot = await getDocs(queryRef);

  return querySnapshot.docs.map((guest) => ({
    id: guest.id,
    ...guest.data(),
  }));
};

export const retrieveGuest = async ({ projectId, guestId }) => {
  const guestRef = doc(firestore, "projects", projectId, "guests", guestId);

  const guestSnapshot = await getDoc(guestRef);

  if (guestSnapshot.exists()) {
    return { id: guestSnapshot.id, ...guestSnapshot.data() };
  } else {
    throw new Error("guest-retrieve/no-such-document");
  }
};

export const deleteGuest = async ({ projectId, guestId }) => {
  const batch = writeBatch(firestore);

  // Delete the guest's answers
  const projectEvents = await listEvents({ projectId });

  for (const event of projectEvents) {
    await removeGuestsToEvent({
      projectId,
      eventId: event.id,
      guests: [guestId],
    });

    const answersRef = collection(
      firestore,
      "projects",
      projectId,
      "events",
      event.id,
      "answers",
    );

    const queryRef = query(answersRef, where("guest", "==", guestId));

    const querySnapshot = await getDocs(queryRef);

    querySnapshot.forEach((answer) => {
      batch.delete(answer.ref);
    });
  }

  await batch.commit();

  // Remove guest from its group
  const updatedGuests = await removeFromGroup({ projectId, guestId });
  // Delete the guest
  const guestRef = doc(firestore, "projects", projectId, "guests", guestId);
  await deleteDoc(guestRef);

  return updatedGuests;
};

const removeFromGroup = async ({ projectId, guestId }) => {
  const updatedGuests = [];
  const guest = await retrieveGuest({ projectId, guestId });
  const guestGroup = guest.group;

  if (guestGroup) {
    await updateGuest({
      projectId,
      guestId,
      fields: { group: deleteField() },
    });

    updatedGuests.push({ guestId, guest: { group: undefined } });

    const guestsRef = collection(firestore, "projects", projectId, "guests");

    const queryRef = query(guestsRef, where("group", "==", guest.group));

    const querySnapshot = await getDocs(queryRef);

    if (querySnapshot.size === 1) {
      const batch = writeBatch(firestore);

      querySnapshot.forEach((guest) => {
        batch.update(guest.ref, { group: deleteField() });
        updatedGuests.push({ guestId: guest.id, guest: { group: undefined } });
      });

      await batch.commit();
    }
  }

  return updatedGuests;
};

const addToGroup = async ({ projectId, guestId, group }) => {
  const updatedGuests = [];
  const [groupId, secondGuest] = group.split("|");

  if (secondGuest) {
    const secondUpdatedGuest = await updateGuest({
      projectId,
      guestId: secondGuest,
      fields: { group: groupId },
    });

    updatedGuests.push({ guestId: secondGuest, guest: secondUpdatedGuest });
  }

  const updatedGuest = await updateGuest({
    projectId,
    guestId,
    fields: { group: groupId },
  });

  updatedGuests.push({ guestId: guestId, guest: updatedGuest });

  return updatedGuests;
};

const api = {
  import: importGuests,
  create: createGuest,
  update: updateGuest,
  list: listGuests,
  retrieve: retrieveGuest,
  delete: deleteGuest,
  removeFromGroup,
  addToGroup,
};

export default api;
