import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import answers from "./answers";
import campaign from "./campaign";
import campaigns from "./campaigns";
import emails from "./emails";
import event from "./event";
import events from "./events";
import guests from "./guests";
import members from "./members";
import project from "./project";
import projects from "./projects";
import questions from "./questions";
import sends from "./sends";
import ui from "./ui";

const reducer = combineReducers({
  answers,
  campaign,
  campaigns,
  emails,
  event,
  events,
  guests,
  members,
  project,
  projects,
  questions,
  sends,
  ui,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
