import api from "modules/Api";
import { functionsEndpoints } from "modules/firebase";
import { makeActionCreator, makeAsyncActionCreator } from "redux-toolbelt";
import { withDelay } from "../utils";

const makeAnswerAction = makeActionCreator.withDefaults({
  prefix: "answers/",
});

const makeAsyncAnswersAction = makeAsyncActionCreator.withDefaults({
  prefix: "answers/",
});

export const resetAnswers = makeAnswerAction("RESET_ANSWERS");
export const fetchAnswers = makeAsyncAnswersAction("FETCH_ANSWERS");
export const createAnswer = makeAsyncAnswersAction("CREATE_ANSWERS");

export const fetch = ({ eventId, projectId }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAnswers());

      const answers = await withDelay(api.answers.list({ eventId, projectId }));

      dispatch(fetchAnswers.success({ answers }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchAnswers.failure({
          errorMessage: "errors:error-message-answers-loading-failed",
        }),
      );
    }
  };
};

export const create = ({ eventId, guestId, projectId, answers }) => {
  return async (dispatch) => {
    const response = await withDelay(
      functionsEndpoints.answerSurvey({
        projectId,
        eventId,
        guestId,
        answers,
      }),
    );

    dispatch(
      createAnswer.success({
        answerId: guestId,
        answer: response.data.answers.answers,
      }),
    );
  };
};
