import { closeModal, openModal } from "./actions";

const initialState = {
  open: false,
  value: { position: "0", text: "", question: null },
};

const configureCardReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case openModal.TYPE:
      return {
        ...state,
        open: true,
        value: {
          ...state.value,
          ...payload,
        },
      };
    case closeModal.TYPE:
      return {
        ...state,
        open: false,
        value: { position: "0", text: "", question: null },
      };
    default:
      return state;
  }
};

export default configureCardReducer;
