import { functionsEndpoints } from "modules/firebase";
import { makeAsyncActionCreator } from "redux-toolbelt";
import { withDelay } from "../utils";

const makeAsyncMembersAction = makeAsyncActionCreator.withDefaults({
  prefix: "members/",
});

export const fetchMembers = makeAsyncMembersAction("FETCH_MEMBERS");
export const deleteMember = makeAsyncMembersAction("DELETE_MEMBER");
export const addMember = makeAsyncMembersAction("ADD_MEMBER");
export const updateMember = makeAsyncMembersAction("UPDATE_MEMBER");

export const fetch = ({ projectId }) => {
  return async (dispatch) => {
    dispatch(fetchMembers());

    try {
      const membersDetails = await withDelay(
        functionsEndpoints.getMembersDetails({ projectId }),
      );

      dispatch(fetchMembers.success({ members: membersDetails.data }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchMembers.failure({
          errorMessage: "errors:error-message-fetch-members-details-failed",
        }),
      );
    }
  };
};

export const invite = ({ email, projectId }) => {
  return async (dispatch) => {
    const newMember = await withDelay(
      functionsEndpoints.inviteMember({ email, projectId }),
    );

    dispatch(addMember.success({ member: newMember.data }));
  };
};
