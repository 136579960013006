import omit from "lodash/omit";
import { storeStatus } from "../utils";
import {
  addGuest,
  deleteGuest,
  fetchGuests,
  resetGuests,
  updateGuest,
} from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const guestsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case resetGuests.TYPE:
      return initialState;
    case fetchGuests.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
      };
    case fetchGuests.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.guests.map((guest) => guest.id),
        byId: payload.guests.reduce(
          (acc, guest) => ({ ...acc, [guest.id]: guest }),
          {},
        ),
      };
    case fetchGuests.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case deleteGuest.success.TYPE:
      const allIdsWithoutDelete = state.allIds.filter(
        (id) => id !== payload.guestId,
      );

      return {
        ...state,
        allIds: allIdsWithoutDelete,
        byId: omit(state.byId, payload.guestId),
      };
    case addGuest.success.TYPE:
      const sortedGuests = [...Object.values(state.byId), payload.guest].sort(
        (a, b) => a.firstname.localeCompare(b.firstname),
      );

      return {
        ...state,
        allIds: sortedGuests.map((guest) => guest.id),
        byId: sortedGuests.reduce(
          (allById, guest) => ({ ...allById, [guest.id]: guest }),
          {},
        ),
      };
    case updateGuest.success.TYPE:
      return {
        ...state,
        byId: Object.entries(state.byId).reduce(
          (allById, [guestId, guestValue]) => {
            if (guestId === payload.guestId) {
              return {
                ...allById,
                [guestId]: {
                  ...guestValue,
                  ...payload.guest,
                },
              };
            }

            return {
              ...allById,
              [guestId]: guestValue,
            };
          },
          {},
        ),
      };
    default:
      return state;
  }
};

export default guestsReducer;
