import { makeActionCreator } from "redux-toolbelt";

const makeModalsSendTestMessageAction = makeActionCreator.withDefaults({
  prefix: "ui/modals/send_test_message/",
});

export const openModal = makeModalsSendTestMessageAction("OPEN_MODAL");
export const closeModal = makeModalsSendTestMessageAction("CLOSE_MODAL");

export const setFields = makeModalsSendTestMessageAction("SET_FIELDS");
