import { closeAlert, openAlert } from "./actions";

const initialState = {
  open: false,
  deleteId: null,
};

const deleteProjectReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case openAlert.TYPE:
      return {
        ...state,
        open: true,
        deleteId: payload.deleteId,
      };
    case closeAlert.TYPE:
      return {
        ...state,
        open: false,
        deleteId: null,
      };

    default:
      return state;
  }
};

export default deleteProjectReducer;
