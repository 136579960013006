import { makeActionCreator } from "redux-toolbelt";

const makeModalsCreateProjectAction = makeActionCreator.withDefaults({
  prefix: "ui/modals/create_project/",
});

export const openModal = makeModalsCreateProjectAction("OPEN_MODAL");
export const closeModal = makeModalsCreateProjectAction("CLOSE_MODAL");

export const setFields = makeModalsCreateProjectAction("SET_FIELDS");
