import omit from "lodash/omit";
import { storeStatus } from "../utils";
import { createCampaign, deleteCampaign, fetchCampaigns } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const campaignsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case fetchCampaigns.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
        error: null,
      };
    case fetchCampaigns.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.campaigns.map((project) => project.id),
        byId: payload.campaigns.reduce(
          (acc, project) => ({ ...acc, [project.id]: project }),
          {},
        ),
      };
    case fetchCampaigns.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case createCampaign.success.TYPE:
      return {
        ...state,
        allIds: [payload.campaign.id, ...state.allIds],
        byId: {
          ...state.byId,
          [payload.campaign.id]: payload.campaign,
        },
      };
    case deleteCampaign.success.TYPE:
      const allIdsWithoutDelete = state.allIds.filter(
        (id) => id !== payload.campaignId,
      );

      return {
        ...state,
        allIds: allIdsWithoutDelete,
        byId: omit(state.byId, payload.campaignId),
      };

    default:
      return state;
  }
};

export default campaignsReducer;
