import { storeStatus } from "../utils";
import { createAnswer, fetchAnswers, resetAnswers } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const answersReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case resetAnswers.TYPE:
      return initialState;
    case fetchAnswers.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
      };
    case fetchAnswers.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.answers.map((answer) => answer.id),
        byId: payload.answers.reduce(
          (acc, answer) => ({ ...acc, [answer.id]: answer }),
          {},
        ),
      };
    case fetchAnswers.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case createAnswer.success.TYPE:
      const alreadyExists = state.allIds.includes(payload.answerId);

      if (alreadyExists) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [payload.answerId]: {
              id: payload.answerId,
              ...payload.answer,
            },
          },
        };
      }

      return {
        ...state,
        allIds: [...state.allIds, payload.answerId],
        byId: {
          ...state.byId,
          [payload.answerId]: {
            id: payload.answerId,
            ...payload.answer,
          },
        },
      };

    default:
      return state;
  }
};

export default answersReducer;
