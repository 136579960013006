import { makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeAsyncCampaignsAction = makeAsyncActionCreator.withDefaults({
  prefix: "campaigns/",
});

export const fetchCampaigns = makeAsyncCampaignsAction("FETCH_CAMPAIGNS");
export const createCampaign = makeAsyncCampaignsAction("CREATE_CAMPAIGN");
export const deleteCampaign = makeAsyncCampaignsAction("DELETE_CAMPAIGN");

export const fetch = ({ projectId }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCampaigns());

      const campaigns = await withDelay(api.campaigns.list({ projectId }));

      dispatch(fetchCampaigns.success({ campaigns }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchCampaigns.failure({
          errorMessage: "errors:error-message-campaigns-loading-failed",
        }),
      );
    }
  };
};

export const create = ({ projectId, campaignContent, campaignDetails }) => {
  return async (dispatch) => {
    const newCampaign = await withDelay(
      api.campaigns.create({ projectId, campaignContent, campaignDetails }),
    );

    dispatch(createCampaign.success({ campaign: newCampaign }));

    return newCampaign.id;
  };
};

export const del = ({ campaignId, projectId }) => {
  return async (dispatch) => {
    await withDelay(api.campaigns.delete({ campaignId, projectId }));

    dispatch(deleteCampaign.success({ campaignId }));
  };
};
