import { makeActionCreator, makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeEventAction = makeActionCreator.withDefaults({
  prefix: "event/",
});

const makeAsyncEventAction = makeAsyncActionCreator.withDefaults({
  prefix: "event/",
});

export const resetEvent = makeEventAction("RESET_EVENT");
export const fetchEvent = makeAsyncEventAction("FETCH_EVENT");
export const updateEvent = makeAsyncEventAction("UPDATE_EVENT");

export const fetch = ({ eventId, projectId }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchEvent());

      const event = await withDelay(
        api.events.retrieve({ eventId, projectId }),
      );

      dispatch(fetchEvent.success({ event }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchEvent.failure({
          errorMessage: "errors:error-message-event-loading-failed",
        }),
      );
    }
  };
};

export const update = ({ eventId, projectId, fields }) => {
  return async (dispatch) => {
    const updatedEvent = await withDelay(
      api.events.update({ eventId, projectId, fields }),
    );

    dispatch(updateEvent.success({ event: updatedEvent }));
  };
};

export const addGuest = ({ eventId, guestId, projectId }) => {
  return async (dispatch) => {
    const updatedEvent = await withDelay(
      api.events.addGuest({ eventId, guestId, projectId }),
    );

    dispatch(updateEvent.success({ event: updatedEvent }));
  };
};

export const removeGuests = ({ eventId, projectId, guests }) => {
  return async (dispatch) => {
    const updatedEvent = await withDelay(
      api.events.removeGuests({ eventId, projectId, guests }),
    );

    dispatch(updateEvent.success({ event: updatedEvent }));
  };
};
