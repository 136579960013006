import { makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeAsyncSendsAction = makeAsyncActionCreator.withDefaults({
  prefix: "sends/",
});

export const fetchSends = makeAsyncSendsAction("FETCH_SENDS");

export const fetch = ({ projectId, campaignId }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSends());

      const sends = await withDelay(
        api.campaigns.listSends({ projectId, campaignId }),
      );

      dispatch(fetchSends.success({ sends }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchSends.failure({
          errorMessage: "errors:error-message-campaigns-loading-failed",
        }),
      );
    }
  };
};
