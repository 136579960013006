import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    backgroundColor: "rgb(15 23 42 / 20%)",
  },
  dialog: {
    borderRadius: "xl",
    backgroundColor: "gray.25",
    overflow: "hidden",
  },
  header: {
    backgroundColor: "white",
  },
  body: {
    py: 6,
    backgroundColor: "white",
    borderBottomRadius: "xl",
    borderBottomWidth: 1,
  },
  footer: {
    gap: 2,
    "> *": {
      width: ["full", "full", "initial"],
    },
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
