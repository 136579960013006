import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { firestore, storage } from "modules/firebase";
import {
  communicationChannels,
  communicationStatus,
  messageSendStatus,
} from "utils";
import { getFileExtension } from "./utils";

export const createCampaign = async ({
  projectId,
  campaignDetails,
  campaignContent,
}) => {
  const campaignsCollection = collection(
    firestore,
    "projects",
    projectId,
    "campaigns",
  );

  const campaignRef = doc(campaignsCollection);

  if (campaignContent.attachment) {
    const attachmentExtension = getFileExtension(
      campaignContent.attachment.type,
    );

    const storageRef = ref(
      storage,
      `project/${projectId}/campaign/${campaignRef.id}/attachment${attachmentExtension}`,
    );

    const response = await uploadBytes(storageRef, campaignContent.attachment);

    campaignContent.attachment = {
      path: response.metadata.fullPath,
    };
  }

  await setDoc(campaignRef, {
    ...campaignDetails,
    ...campaignContent,
    version: 2,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    status:
      campaignDetails.channel === communicationChannels.WHATSAPP.value
        ? communicationStatus.PENDING.value
        : communicationStatus.READY.value,
  });

  const newCampaign = await getDoc(campaignRef);

  return {
    id: newCampaign.id,
    ...newCampaign.data(),
  };
};

export const updateCampaign = async ({ projectId, campaignId, fields }) => {
  const campaignRef = doc(
    firestore,
    "projects",
    projectId,
    "campaigns",
    campaignId,
  );

  const fieldsToUpdate = Object.entries(fields).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return { ...acc, [key]: deleteField() };
    }

    return { ...acc, [key]: value };
  }, {});

  await updateDoc(campaignRef, {
    ...fieldsToUpdate,
    updated_at: serverTimestamp(),
  });

  const updatedCampaign = await getDoc(campaignRef);

  return updatedCampaign.data();
};

export const listCampaigns = async ({ projectId }) => {
  const campaignsCollection = collection(
    firestore,
    "projects",
    projectId,
    "campaigns",
  );

  const queryRef = query(campaignsCollection, orderBy("created_at", "desc"));

  const campaignsSnapshot = await getDocs(queryRef);

  return campaignsSnapshot.docs.map((campaign) => ({
    id: campaign.id,
    ...campaign.data(),
  }));
};

export const retrieveCampaign = async ({ projectId, campaignId }) => {
  const campaignRef = doc(
    firestore,
    "projects",
    projectId,
    "campaigns",
    campaignId,
  );

  const campaignSnapshot = await getDoc(campaignRef);

  if (campaignSnapshot.exists()) {
    const campaign = campaignSnapshot.data();
    const campaignObject = { id: campaignSnapshot.id, ...campaign };

    if (campaign.channel === communicationChannels.WHATSAPP.value) {
      const templatesCollection = collection(firestore, "whatsapp_templates");

      const templateQueryRef = query(
        templatesCollection,
        where("metadata.project", "==", projectId),
        where("metadata.campaign", "==", campaignSnapshot.id),
      );

      const templateQuerySnapshot = await getDocs(templateQueryRef);

      if (!templateQuerySnapshot.empty) {
        const template = templateQuerySnapshot.docs[0];

        campaignObject["template"] = { id: template.id, ...template.data() };
      }
    }

    return campaignObject;
  } else {
    throw new Error("campaign-retrieve/no-such-document");
  }
};

export const deleteCampaign = async ({ projectId, campaignId }) => {
  const campaignRef = doc(
    firestore,
    "projects",
    projectId,
    "campaigns",
    campaignId,
  );
  await deleteDoc(campaignRef);

  return "success";
};

const sendCampaign = async ({ projectId, campaignId, recipients }) => {
  const campaignSendsCollection = collection(
    firestore,
    "projects",
    projectId,
    "campaigns",
    campaignId,
    "sends",
  );

  const sendRef = await addDoc(campaignSendsCollection, {
    recipients,
    sent_at: serverTimestamp(),
    status: messageSendStatus.SCHEDULED.value,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
  });

  const send = await getDoc(sendRef);

  return {
    id: send.id,
    ...send.data(),
  };
};

export const listCampaignSends = async ({ projectId, campaignId }) => {
  const campaignSendsCollection = collection(
    firestore,
    "projects",
    projectId,
    "campaigns",
    campaignId,
    "sends",
  );

  const queryRef = query(campaignSendsCollection, orderBy("sent_at", "desc"));

  const sendsSnapshot = await getDocs(queryRef);

  return sendsSnapshot.docs.map((send) => ({
    id: send.id,
    ...send.data(),
  }));
};

const api = {
  create: createCampaign,
  update: updateCampaign,
  list: listCampaigns,
  retrieve: retrieveCampaign,
  delete: deleteCampaign,
  send: sendCampaign,
  listSends: listCampaignSends,
};

export default api;
