import { makeActionCreator, makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { resetAnswers } from "../answers/actions";
import { resetEvent } from "../event/actions";
import { resetEvents } from "../events/actions";
import { resetGuests } from "../guests/actions";
import { resetQuestions } from "../questions/actions";
import { withDelay } from "../utils";

const makeProjectAction = makeActionCreator.withDefaults({
  prefix: "project/",
});

const makeAsyncProjectAction = makeAsyncActionCreator.withDefaults({
  prefix: "project/",
});

export const resetProject = makeProjectAction("RESET_PROJECT");
export const fetchProject = makeAsyncProjectAction("FETCH_PROJECT");
export const updateProject = makeAsyncProjectAction("UPDATE_PROJECT");

export const fetch = ({ projectId }) => {
  return async (dispatch) => {
    dispatch(resetEvents());
    dispatch(resetEvent());
    dispatch(resetGuests());
    dispatch(resetAnswers());
    dispatch(resetQuestions());
    dispatch(fetchProject());

    try {
      const project = await withDelay(api.projects.retrieve({ projectId }));

      dispatch(fetchProject.success({ project }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchProject.failure({
          errorMessage: "errors:error-message-project-loading-failed",
        }),
      );
    }
  };
};

export const update = ({ projectId, fields }) => {
  return async (dispatch) => {
    const updatedFields = await withDelay(
      api.projects.update({ projectId, fields }),
    );

    dispatch(updateProject.success({ updatedFields }));
  };
};

export const removeMember = ({ projectId, memberId }) => {
  return async (dispatch) => {
    const updatedFields = await withDelay(
      api.projects.removeMember({ projectId, memberId }),
    );

    dispatch(updateProject.success({ updatedFields }));
  };
};

export const addNewTag = ({ projectId, tag }) => {
  return async (dispatch) => {
    await api.projects.addNewTag({ projectId, tag });
    const project = await api.projects.retrieve({ projectId });

    dispatch(fetchProject.success({ project }));
  };
};
