import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "modules/firebase";
import { updateEvent } from "./events";

export const createQuestion = async ({ question, eventId, projectId }) => {
  const questionsRef = collection(
    firestore,
    "projects",
    projectId,
    "events",
    eventId,
    "questions",
  );

  const questionRef = await addDoc(questionsRef, question);

  await updateEvent({
    projectId,
    eventId,
    fields: {
      questions_ids: arrayUnion(questionRef.id),
    },
  });

  const newQuestion = await getDoc(questionRef);

  return { id: newQuestion.id, ...newQuestion.data() };
};

export const updateQuestion = async ({
  projectId,
  eventId,
  questionId,
  fields,
}) => {
  const questionRef = doc(
    firestore,
    "projects",
    projectId,
    "events",
    eventId,
    "questions",
    questionId,
  );

  await updateDoc(questionRef, { ...fields });

  const updatedQuestion = await getDoc(questionRef);

  return updatedQuestion.data();
};

export const listQuestions = async ({ eventId, projectId }) => {
  const questionsRef = collection(
    firestore,
    "projects",
    projectId,
    "events",
    eventId,
    "questions",
  );

  const questionsSnapshot = await getDocs(questionsRef);

  return questionsSnapshot.docs.map((question) => ({
    id: question.id,
    ...question.data(),
  }));
};

export const deleteQuestion = async ({ projectId, eventId, questionId }) => {
  const questionRef = doc(
    firestore,
    "projects",
    projectId,
    "events",
    eventId,
    "questions",
    questionId,
  );
  await deleteDoc(questionRef);

  await updateEvent({
    projectId,
    eventId,
    fields: {
      questions_ids: arrayRemove(questionId),
    },
  });

  return "success";
};

const api = {
  create: createQuestion,
  update: updateQuestion,
  list: listQuestions,
  delete: deleteQuestion,
};

export default api;
