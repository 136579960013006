import omit from "lodash/omit";
import { storeStatus } from "../utils";
import { addMember, deleteMember, fetchMembers, updateMember } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const membersReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case fetchMembers.TYPE:
      return {
        ...state,
        allIds: [],
        byId: {},
        status: storeStatus.LOADING,
        error: null,
      };
    case fetchMembers.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.members.map((member) => member.uid),
        byId: payload.members.reduce(
          (acc, member) => ({ ...acc, [member.uid]: member }),
          {},
        ),
      };
    case fetchMembers.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case addMember.success.TYPE:
      return {
        ...state,
        allIds: [...state.allIds, payload.member.uid],
        byId: {
          ...state.byId,
          [payload.member.uid]: payload.member,
        },
      };
    case deleteMember.success.TYPE:
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== payload.memberId),
        byId: omit(state.byId, payload.memberId),
      };
    case updateMember.success.TYPE:
      return {
        ...state,
        byId: Object.entries(state.byId).reduce(
          (allById, [memberId, memberValue]) => {
            if (memberId === payload.memberId) {
              return {
                ...allById,
                [memberId]: {
                  ...memberValue,
                  ...payload.member,
                },
              };
            }

            return {
              ...allById,
              [memberId]: memberValue,
            };
          },
          {},
        ),
      };

    default:
      return state;
  }
};

export default membersReducer;
