import { auth } from "modules/firebase";
import { makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { resetProject } from "../project/actions";
import { withDelay } from "../utils";

const makeAsyncProjectsAction = makeAsyncActionCreator.withDefaults({
  prefix: "projects/",
});

export const fetchProjects = makeAsyncProjectsAction("FETCH_PROJECTS");
export const createProject = makeAsyncProjectsAction("CREATE_PROJECT");
export const deleteProject = makeAsyncProjectsAction("DELETE_PROJECT");

export const fetch = () => {
  return async (dispatch) => {
    dispatch(fetchProjects());
    dispatch(resetProject());

    try {
      const projects = await withDelay(
        api.projects.list({ user: auth.currentUser }),
      );

      dispatch(fetchProjects.success({ projects }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchProjects.failure({
          errorMessage: "errors:error-message-projects-loading-failed",
        }),
      );
    }
  };
};

export const create = ({ project }) => {
  return async (dispatch) => {
    const newProject = await withDelay(
      api.projects.create({ project, user: auth.currentUser }),
    );

    dispatch(
      createProject.success({
        project: newProject,
      }),
    );
  };
};

export const del = ({ projectId }) => {
  return async (dispatch) => {
    await withDelay(api.projects.delete({ projectId }));

    dispatch(deleteProject.success({ projectId }));
  };
};
