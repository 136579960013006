import { storeStatus } from "../utils";
import { fetchCampaign, updateCampaign } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  value: null,
  error: null,
};

const campaignReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case fetchCampaign.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
      };
    case fetchCampaign.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        value: payload.campaign,
      };
    case fetchCampaign.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };

    case updateCampaign.success.TYPE:
      return {
        ...state,
        value: {
          ...state.value,
          ...payload.campaign,
        },
      };

    default:
      return state;
  }
};

export default campaignReducer;
