import { makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeAsyncEmailsAction = makeAsyncActionCreator.withDefaults({
  prefix: "emails/",
});

export const fetchEmails = makeAsyncEmailsAction("FETCH_EMAILS");

export const fetch = ({ campaignId, projectId, sendId }) => {
  return async (dispatch) => {
    dispatch(fetchEmails());

    try {
      const emails = await withDelay(
        api.emails.list({ campaignId, projectId, sendId }),
      );

      dispatch(fetchEmails.success({ emails }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchEmails.failure({
          errorMessage:
            "errors:error-message-campaign-report-info-loading-failed",
        }),
      );
    }
  };
};
