import { makeActionCreator, makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeEventsAction = makeActionCreator.withDefaults({
  prefix: "events/",
});

const makeAsyncEventsAction = makeAsyncActionCreator.withDefaults({
  prefix: "events/",
});

export const resetEvents = makeEventsAction("RESET_EVENTS");
export const fetchEvents = makeAsyncEventsAction("FETCH_EVENTS");
export const deleteEvent = makeAsyncEventsAction("DELETE_EVENT");
export const createEvent = makeAsyncEventsAction("CREATE_EVENT");

export const fetch = ({ projectId }) => {
  return async (dispatch) => {
    dispatch(fetchEvents());

    try {
      const events = await withDelay(api.events.list({ projectId }));

      dispatch(fetchEvents.success({ events }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchEvents.failure({
          errorMessage: "errors:error-message-events-loading-failed",
        }),
      );
    }
  };
};

export const create = ({ projectId, event }) => {
  return async (dispatch) => {
    const newEvent = await withDelay(api.events.create({ projectId, event }));

    dispatch(
      createEvent.success({
        event: newEvent,
      }),
    );
  };
};

export const del = ({ eventId, projectId }) => {
  return async (dispatch) => {
    await withDelay(api.events.delete({ eventId, projectId }));

    dispatch(deleteEvent.success({ eventId }));
  };
};
