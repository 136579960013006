import { auth, onAuthStateChanged } from "modules/firebase";
import React, { createContext, useState } from "react";

export const AuthContext = createContext(null);

const Auth = ({ children }) => {
  const [isAuthenticated, setAuthentication] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, (currentUser) => {
    setIsLoading(false);

    if (currentUser) {
      setAuthentication(true);
      setUser(currentUser);
    } else {
      // User is signed out
      setAuthentication(false);
      setUser(null);
    }
  });

  const contextValues = {
    isAuthenticated,
    isLoading,
    user,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default Auth;
