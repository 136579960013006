import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { connectStorageEmulator, getStorage } from "firebase/storage";

export {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
getPerformance(firebaseApp);

export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1");
export const storage = getStorage(firebaseApp);

export const initializeFirebase = () => {
  if (process.env.NODE_ENV === "development") {
    global.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN || true;

    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectStorageEmulator(storage, "localhost", 9199);
  }

  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(
      "6Leb-VcdAAAAAOlj0mj7wt9P2ZzRb-PH5tYIa9KA",
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
};

const addGuestFunction = httpsCallable(functions, "addGuest");
const answerSurveyFunction = httpsCallable(functions, "answerSurvey");
const getMembersDetailsFunction = httpsCallable(functions, "getMembersDetails");
const inviteMemberFunction = httpsCallable(functions, "inviteMember");
const retrieveGuestAnswersFunction = httpsCallable(
  functions,
  "retrieveGuestAnswers",
);
const retrieveGuestDetailsFunction = httpsCallable(
  functions,
  "retrieveGuestDetails",
);
const retrieveProjectDetailsFunction = httpsCallable(
  functions,
  "retrieveProjectDetails",
);
const retrieveSurveyFunction = httpsCallable(functions, "retrieveSurvey");
const sendTestMessageFunction = httpsCallable(functions, "sendTestMessage");
const validateSlugFunction = httpsCallable(functions, "validateSlug");

export const functionsEndpoints = {
  addGuest: addGuestFunction,
  answerSurvey: answerSurveyFunction,
  getMembersDetails: getMembersDetailsFunction,
  inviteMember: inviteMemberFunction,
  retrieveGuestAnswers: retrieveGuestAnswersFunction,
  retrieveGuestDetails: retrieveGuestDetailsFunction,
  retrieveProjectDetails: retrieveProjectDetailsFunction,
  retrieveSurvey: retrieveSurveyFunction,
  sendTestMessage: sendTestMessageFunction,
  validateSlug: validateSlugFunction,
};

export default firebaseApp;
