import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { firestore } from "modules/firebase";

export const listAnswers = async ({ eventId, projectId }) => {
  const answersRef = collection(
    firestore,
    "projects",
    projectId,
    "events",
    eventId,
    "answers",
  );

  const answersSnapshot = await getDocs(answersRef);

  return answersSnapshot.docs.map((answer) => ({
    id: answer.id,
    ...answer.data().answers,
  }));
};

export const deleteAnswer = async ({ projectId, eventId, answerId }) => {
  const answerRef = doc(
    firestore,
    "projects",
    projectId,
    "events",
    eventId,
    "answers",
    answerId,
  );
  await deleteDoc(answerRef);

  return "success";
};

const api = {
  list: listAnswers,
};

export default api;
