import { closeModal, openModal } from "./actions";

const initialState = { open: false, value: null };

const guestChatReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case openModal.TYPE:
      return {
        ...state,
        open: true,
        value: payload.messages,
      };
    case closeModal.TYPE:
      return {
        ...state,
        open: false,
        value: null,
      };
    default:
      return state;
  }
};

export default guestChatReducer;
