import { makeActionCreator, makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { getValue } from "../event/selectors";
import { withDelay } from "../utils";

const makeQuestionsAction = makeActionCreator.withDefaults({
  prefix: "questions/",
});

const makeAsyncQuestionsAction = makeAsyncActionCreator.withDefaults({
  prefix: "questions/",
});

export const resetQuestions = makeQuestionsAction("RESET_QUESTIONS");
export const fetchQuestions = makeAsyncQuestionsAction("FETCH_QUESTIONS");
export const addQuestion = makeAsyncQuestionsAction("ADD_QUESTION");
export const updateQuestions = makeAsyncQuestionsAction("UPDATE_QUESTIONS");
export const deleteQuestion = makeAsyncQuestionsAction("DELETE_QUESTION");
export const updateQuestion = makeAsyncQuestionsAction("UPDATE_QUESTION");

export const fetch = ({ eventId, projectId }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchQuestions());

      const questions = await withDelay(
        api.questions.list({ eventId, projectId }),
      );
      const questionsMap = questions.reduce(
        (acc, question) => ({ ...acc, [question.id]: question }),
        {},
      );

      let orderedQuestions = null;

      do {
        const event = getValue(getState());

        if (event) {
          orderedQuestions = event.questions_ids.map(
            (questionId) => questionsMap[questionId],
          );

          dispatch(fetchQuestions.success({ questions: orderedQuestions }));
        }
      } while (orderedQuestions === null);
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchQuestions.failure({
          errorMessage: "errors:error-message-event-loading-failed",
        }),
      );
    }
  };
};

export const create = ({ eventId, projectId, question }) => {
  return async (dispatch) => {
    const newQuestion = await withDelay(
      api.questions.create({ eventId, projectId, question }),
    );

    dispatch(addQuestion.success({ question: newQuestion }));
  };
};

export const update = ({ eventId, projectId, questionId, fields }) => {
  return async (dispatch) => {
    const updatedQuestion = await withDelay(
      api.questions.update({ eventId, projectId, questionId, fields }),
    );

    dispatch(updateQuestion.success({ questionId, question: updatedQuestion }));
  };
};

export const del = ({ eventId, projectId, questionId }) => {
  return async (dispatch) => {
    await withDelay(api.questions.delete({ eventId, projectId, questionId }));

    dispatch(deleteQuestion.success({ questionId }));
  };
};
