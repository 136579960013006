import { switchTab } from "./actions";

const initialState = null;

const eventPageReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case switchTab.TYPE:
      return payload.tab;
    default:
      return state;
  }
};

export default eventPageReducer;
