import { Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";

export const Loader = ({ message }) => {
  return (
    <VStack spacing={3}>
      <Spinner size="lg" color="primary.600" />

      <Text>{message}</Text>
    </VStack>
  );
};
