import { combineReducers } from "redux";
import alertsReducer from "./alerts";
import modalsReducer from "./modals";
import tabsReducer from "./tabs";

const uiReducer = combineReducers({
  modals: modalsReducer,
  alerts: alertsReducer,
  tabs: tabsReducer,
});

export default uiReducer;
