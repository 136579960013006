import { makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeAsyncCampaignAction = makeAsyncActionCreator.withDefaults({
  prefix: "campaign/",
});

export const fetchCampaign = makeAsyncCampaignAction("FETCH_CAMPAIGN");
export const updateCampaign = makeAsyncCampaignAction("UPDATE_CAMPAIGN");
export const sendCampaign = makeAsyncCampaignAction("SEND_CAMPAIGN");

export const fetch = ({ campaignId, projectId }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCampaign());

      const campaign = await withDelay(
        api.campaigns.retrieve({ campaignId, projectId }),
      );
      dispatch(fetchCampaign.success({ campaign }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchCampaign.failure({
          errorMessage: "errors:error-message-campaign-loading-failed",
        }),
      );
    }
  };
};

export const update = ({ campaignId, projectId, fields }) => {
  return async (dispatch) => {
    const updatedCampaign = await withDelay(
      api.campaigns.update({
        campaignId,
        projectId,
        fields,
      }),
    );

    dispatch(updateCampaign.success({ campaign: updatedCampaign }));

    return updatedCampaign;
  };
};

export const send = ({ campaignId, projectId, recipients }) => {
  return async (dispatch) => {
    await withDelay(api.campaigns.send({ campaignId, projectId, recipients }));

    dispatch(sendCampaign.success({ campaignId }));
  };
};
