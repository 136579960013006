import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "modules/firebase";

export const listEmails = async ({ projectId, campaignId, sendId }) => {
  const mailCollection = collection(firestore, "mail");
  const messagesCollection = collection(firestore, "messages");

  const queryConstraints = [
    where("metadata.project", "==", projectId),
    where("metadata.campaign", "==", campaignId),
  ];

  if (sendId) {
    queryConstraints.push(where("metadata.send", "==", sendId));
  }

  const queryMailRef = query(mailCollection, ...queryConstraints);

  const queryMessagesRef = query(messagesCollection, ...queryConstraints);

  const emailsSnapshot = await getDocs(queryMailRef);
  const messagesSnapshot = await getDocs(queryMessagesRef);

  const emails = emailsSnapshot.docs.map((email) => ({
    id: email.id,
    ...email.data(),
  }));

  const messages = messagesSnapshot.docs.map((message) => ({
    id: message.id,
    ...message.data(),
  }));

  return [...emails, ...messages];
};

const api = {
  list: listEmails,
};

export default api;
