import { closeModal, openModal, setFields } from "./actions";

const initialState = { open: false, fields: {} };

const sendTestMessageReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case openModal.TYPE:
      return {
        ...state,
        open: true,
        fields: {},
      };
    case closeModal.TYPE:
      return {
        ...state,
        open: false,
      };
    case setFields.TYPE:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload.fields,
        },
      };
    default:
      return state;
  }
};

export default sendTestMessageReducer;
