import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "modules/firebase";

const listChats = async ({ projectId }) => {
  const chatsCollection = collection(firestore, "whatsapp_chats");

  const queryRef = query(
    chatsCollection,
    where("metadata.project", "array-contains", projectId),
    orderBy("timestamp", "desc"),
  );

  const querySnapshot = await getDocs(queryRef);

  return querySnapshot.docs.map((chat) => ({
    id: chat.id,
    ...chat.data(),
  }));
};

const updateChat = async ({ chatId, fields }) => {
  const chatRef = doc(firestore, "whatsapp_chats", chatId);

  await updateDoc(chatRef, { ...fields, updated_at: serverTimestamp() });
};

const api = {
  list: listChats,
  update: updateChat,
};

export default api;
