import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  tabpanel: {
    px: 0,
    py: 6,
  },
});
// export the base styles in the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle });
