export const storeStatus = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export const REQUEST_DELAY = 350;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const withDelay = async (fn) => {
  const [result] = await Promise.allSettled([fn, delay(REQUEST_DELAY)]);

  if (result.status === "fulfilled") {
    return result.value;
  }

  throw new Error(result.reason);
};
