import { closeModal, openModal } from "./actions";

const initialState = { open: false };

const inviteMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case openModal.TYPE:
      return {
        ...state,
        open: true,
      };
    case closeModal.TYPE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default inviteMemberReducer;
