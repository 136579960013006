import { storeStatus } from "../utils";
import { fetchSends } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const sendsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case fetchSends.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
        error: null,
      };
    case fetchSends.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.sends.map((project) => project.id),
        byId: payload.sends.reduce(
          (acc, project) => ({ ...acc, [project.id]: project }),
          {},
        ),
      };
    case fetchSends.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };

    default:
      return state;
  }
};

export default sendsReducer;
