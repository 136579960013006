import omit from "lodash/omit";
import { storeStatus } from "../utils";
import {
  addQuestion,
  deleteQuestion,
  fetchQuestions,
  resetQuestions,
  updateQuestion,
  updateQuestions,
} from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  allIds: [],
  byId: {},
  error: null,
};

const questionsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case resetQuestions.TYPE:
      return initialState;
    case fetchQuestions.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
      };
    case fetchQuestions.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        allIds: payload.questions.map((question) => question.id),
        byId: payload.questions.reduce(
          (acc, question) => ({ ...acc, [question.id]: question }),
          {},
        ),
      };
    case fetchQuestions.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };
    case addQuestion.success.TYPE:
      return {
        ...state,
        allIds: [...state.allIds, payload.question.id],
        byId: {
          ...state.byId,
          [payload.question.id]: payload.question,
        },
      };
    case deleteQuestion.success.TYPE:
      const allIdsWithoutDelete = state.allIds.filter(
        (id) => id !== payload.questionId,
      );

      return {
        ...state,
        allIds: allIdsWithoutDelete,
        byId: omit(state.byId, payload.questionId),
      };
    case updateQuestion.success.TYPE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.questionId]: {
            ...state.byId[payload.questionId],
            ...payload.question,
          },
        },
      };
    case updateQuestions.TYPE:
    case updateQuestions.failure.TYPE:
      return {
        ...state,
        allIds: payload.questionsIds,
      };

    default:
      return state;
  }
};

export default questionsReducer;
