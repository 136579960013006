import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Provider as ReduxProvider } from "react-redux";
import Auth from "../Auth";
import store from "../Store";
import { chakraTheme } from "./theme";

const Providers = ({ children }) => (
  <ChakraProvider theme={chakraTheme} portalZIndex={11}>
    <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} />

    <ReduxProvider store={store}>
      <Auth>{children}</Auth>
    </ReduxProvider>
  </ChakraProvider>
);

export default Providers;
