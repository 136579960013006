import { storeStatus } from "../utils";
import { fetchProject, resetProject, updateProject } from "./actions";

const initialState = {
  status: storeStatus.IDLE,
  value: null,
  error: null,
};

const projectReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case resetProject.TYPE:
      return initialState;
    case fetchProject.TYPE:
      return {
        ...state,
        status: storeStatus.LOADING,
      };
    case fetchProject.success.TYPE:
      return {
        ...state,
        status: storeStatus.SUCCESS,
        value: payload.project,
      };
    case fetchProject.failure.TYPE:
      return {
        ...state,
        status: storeStatus.ERROR,
        error: payload.errorMessage,
      };

    case updateProject.success.TYPE:
      return {
        ...state,
        value: {
          ...state.value,
          ...payload.updatedFields,
        },
      };

    default:
      return state;
  }
};

export default projectReducer;
